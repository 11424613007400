<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: {
    annee: {
      type: Number,
      default: null,
    },
  },
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataNombre: [],
      mois: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ],
      dataMois: [],
    };
  },
  watch: {
    annee: {
      handler(val) {
        console.log("val:", val);
        this.loadData();
      },
    },
  },
  async mounted() {
    //console.log("this.annee:", this.annee);
    this.loadData();
    
  },
  methods: {
    async loadData() {
      this.dataNombre = [];
      this.dataMois = [];

      const stat = await apiRequest(
        "GET",
        "assurance/start",
        undefined,
        false
      );

      if (stat && stat.data) {
        var dataStat = stat.data;
        console.log("data stat liste ", dataStat);
        this.dataNombre=[]
        this.dataMois=[]
        dataStat.all.forEach((element) => {
      
          if (element.annee == this.annee) {
            this.dataNombre.push(element.nombre);
            this.dataMois.push(this.mois[element.mois - 1]);
          }
         
         
        });
        console.log("nombre de souscription ",   this.dataNombre
        );

        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "Souscriptions",
                barPercentage: 0.6,
                backgroundColor: "#89b800",
                borderColor: "#89b800",
                borderWidth: 2,
                shadowOffsetX:-1,
                shadowOffsetY : 1,
                shadowColor :"#79a300",
         
                hoverBackgroundColor: "#89b800",
                hoverBorderColor: "#89b800",
                data: this.dataNombre,
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );
      }
    },


   
  },
};
</script>


